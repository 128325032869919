import React from "react";

import Hero from "../../common/hero";
import Contenidos from "./layout/contenidos";
import Objetivos from "./layout/objetivos";
import Talleres from "./layout/talleres";

const Verano2019 = () => (
  <>
    <Hero texto="Verano 2019" className="is-small" />
    <Contenidos />
    <Objetivos />
    <Talleres />
  </>
);

export default Verano2019;
