import React from "react";
import Hero from "../../../common/hero";

const subtitulo = `
  Para alcanzar los objetivos propuestos y contenidos a trabajar se llevarán
  a cabo una serie de talleres didácticos que acogen multitud de actividades
  innovadoras y divertidas para los niños y niñas.
`;

const info = [
  {
    key: 0,
    titulo: "Expresión corporal, teatro y deporte en inglés.",
    color: "is-link",
    radius: "radius-top-left"
  },
  {
    key: 1,
    titulo: "Talleres de cocina.",
    color: "is-warning",
    radius: "is-radiusless"
  },
  {
    key: 2,
    titulo: "Talleres de historia experimental.",
    color: "is-success",
    radius: "radius-top-right"
  },
  {
    key: 3,
    titulo: "Talleres de creación artística.",
    color: "is-info",
    radius: "radius-bottom-left"
  },
  {
    key: 4,
    titulo: "Gymkanas, pistas de rastreo.",
    color: "is-warning",
    radius: "is-radiusless"
  },
  {
    key: 5,
    titulo: "Actividades acuáticas y salidas escolares",
    color: "is-success",
    radius: "radius-bottom-right"
  }
];

const Talleres = () => (
  <div className="hero">
    <div className="container">
      <Hero texto="Talleres" subtexto={subtitulo} />
    </div>
    <div className="container">
      <div className="columns is-multiline is-centered">
        {info.map(item => (
          <div className="column is-one-third">
            <article className={`message ${item.color}`} key={item.key}>
              <div className={`message-header ${item.radius}`}>
                {item.titulo}
              </div>
            </article>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Talleres;
