import React from "react";
import Hero from "../../../common/hero";

const info = [
  {
    key: 0,
    titulo: "Mi maravilloso planeta",
    texto:
      "naturaleza, seres vivos, planetas de la via láctea, planetario, tipos de seres vivos.",
    color: "is-primary",
    radius: "radius-top"
  },
  {
    key: 1,
    titulo: "¡Todos somos súper poderosos!",
    texto:
      "Superhéroes, características, habilidades, musicoterapia, educación emocional, cine, descubrimos nuestros poderes.",
    color: "is-warning",
    radius: "is-radiusless"
  },
  {
    key: 2,
    titulo: "Cuerpo humano y sus posibilidades",
    texto:
      "huesos, músculos, curiosidades, deportes, comida saludable, yoga, zumba.",
    color: "is-info",
    radius: "is-radiusless"
  },
  {
    key: 3,
    titulo: "Diversidad cultural y medios de comunicación",
    texto: "razas, etnias, música",
    color: "is-danger",
    radius: "is-radiusless"
  },
  {
    key: 4,
    titulo: "Ecología y reciclaje",
    texto: "reducir, reutilizar, reciclar, tipos de plantas, alimentación",
    color: "is-success",
    radius: "radius-bottom"
  }
];

const Contenidos = () => (
  <div className="hero is-small">
    <Hero texto="Contenidos a trabajar" subtexto="Cada semana un eje temático" />
    <div className="container">
      {info.map(item => (
        <article className={`message ${item.color}`} key={item.key}>
          <div className={`message-header ${item.radius}`}>
            {item.titulo}: {item.texto}
          </div>
        </article>
      ))}
    </div>
  </div>
);

export default Contenidos;
