import React from "react";
import Hero from "../../../common/hero";
import "./verano2019.scss";

const info = [
  {
    key: 0,
    titulo: "Aumentar la capacidad creativa de los participantes.",
    color: "is-info",
    radius: "radius-top"
  },
  {
    key: 1,
    titulo: "Desarrollar valores de respeto hacia los compañeros y el medio.",
    color: "is-danger",
    radius: "radius-top"
  },
  {
    key: 2,
    titulo: "Ofrecer un espacio lúdico, donde los niños/as sigan aprendiendo.",
    color: "is-danger",
    radius: "is-radiusless	"
  },
  {
    key: 3,
    titulo: "Motivar al niño/a a través de juegos, talleres y participación.",
    color: "is-link",
    radius: "is-radiusless"
  },
  {
    key: 4,
    titulo: "Fomentar el trabajo en grupo.",
    color: "is-success",
    radius: "radius-bottom"
  },
  {
    key: 5,
    titulo: "Conocer recetas básicas de cocina.",
    color: "is-warning",
    radius: "radius-bottom"
  }
];

const Objetivos = () => (
  <div className="hero is-small">
    <Hero texto="Objetivos" />
    <div className="container">
      <div className="columns is-multiline is-centered">
        {info.map(item => (
          <div className="column is-half">
            <article
              className={`message is-medium ${item.color}`}
              key={item.key}
            >
              <div className={`message-header ${item.radius}`}>
                {item.titulo}
              </div>
            </article>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Objetivos;
